import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><em parentName="p">{`Deload Week`}</em></p>
    <p>{`Lower Body Mobility :15`}</p>
    <p>{`then,`}</p>
    <p>{`Assault Bike Intervals :20 on, :40 recovery`}</p>
    <p>{`Minute 1: 60rpm`}</p>
    <p>{`Minute 2: 70rpm`}</p>
    <p>{`Minute 3: 80rpm`}</p>
    <p>{`Minute 4: 90rpm`}</p>
    <p>{`Minute 5: 100rpm`}</p>
    <p><em parentName="p">{`(or 6/7/8/9/10 on RPE scale)`}</em></p>
    <p>{`x 2 Rounds.`}</p>
    <p>{`then,`}</p>
    <p>{`3 Rounds for time of:`}</p>
    <p>{`25-Air Squats`}</p>
    <p>{`20-Box Jump Overs (24/20″)`}</p>
    <p>{`15-Back Extensions`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      